<template>
  <div class="error">
    <el-result style="width: 100%" icon="error" title="同步失败">
      <div slot="subTitle" style="line-height: 1.5; color: #999">
        身份同步失败<br />稍后将返回至应用首页
      </div>
    </el-result>
  </div>
</template>

<script>
export default {
  mounted() {
    let opener = window.opener;
    // 只有 http://127.0.0.1:5500 网页打开的页面，才可以收到来自 B 页面的信息
    opener.postMessage('1', 'https://mashangqian.yoojet.com');
  }
};
</script>

<style>
.error {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>